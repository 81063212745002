.main__row {
    --bs-gutter-x:unset !important;
}

.mobile__nav {
    
    display: block !important;
    width: 240px;
    position: absolute;
    z-index: 2;
}
  
:global(.form-container input),
:global(.form-container .btn-login) {
    width: 100%;
    margin: 5px 0;
    height: 45px;
    vertical-align: middle;
    font-size: 16px;
  }
  
:global(.form-container input) {
    border: 1px solid #dddfe2;
    color: #1d2129;
    padding: 0 8px;
    outline: none;
  }
  
:global(.form-container input:focus) {
    border-color: #ea484d;
    box-shadow: 0 0 0 2px #e7f3ff;
  }
  
@media screen and (max-width: 1170px) {
    .main__section {
        width: 100% !important;      
   }
}
/*
@media only screen and (max-width:600px) {
    .main__row {
        width: 100%;
    }
}*/