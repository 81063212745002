/* var */

.userProfile {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}


.sidebarMenuLink {
    text-decoration: none;
    color: #262626;
    font-family: mulish,sans-serif;
    margin-left: 5px;
}

.sidebarMenu:hover .sidebarMenuLink {
    color: #ea484d;
    transition: 250ms;
}

.sidebarMenu:hover .sidebarMenuIcon {
    color: #ea484d;
    transition: 250ms;
}

.userProfilePic img{
    border-radius: 50%;
}


.navHeader {
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    font-weight: 600;
}

.menuButton {
    display: none !important;
}

/* media */

@media only screen and (max-width:1170px) {
    .menuButton {
        display: block !important;
    }
}