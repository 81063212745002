.page__loading {
    position: fixed;
    width: 100%;
    z-index: 99999;
    background-color: rgba(0,0,0,0.4);
    height: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  