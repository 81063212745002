.sidebar,.sidebar__mobile {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding: 0 !important;
    z-index: 12;
    height: 300vh;
    background-color: #f6f7fb;
}
.sidebar .sidebar__inner,.sidebar__mobile .sidebar__inner {
    background: white;
    position: fixed;
    width: inherit;
    padding-bottom: 2rem;
    height: inherit;
}

.sidebar .sidebar__nav__list,.sidebar__mobile .sidebar__nav__list {
    display: flex;
    flex-direction: column;
    padding-left: 0px !important;
}

@media screen and (max-width: 1170px) {
    .sidebar {
        display: none !important;        
   }
}
.sidebar .sidebar__logo,.sidebar__mobile .sidebar__logo {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: center;
    border-bottom: 2px solid #e5e5e5;
    padding-bottom: 1rem;
}
.sidebar .sidebar__logo img,.sidebar__mobile .sidebar__logo img {
    width: 50px;
    height: 50px;
}

.sidebar__active {
    display: flex !important;
    flex-direction: column;
}

.sidebarButton {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-bottom: 2px solid #e5e5e5;
}

.sidebarLink {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.sidebar__close__button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-bottom: 2px solid #e5e5e5;
    margin-top: 1rem;
    padding-bottom: 1rem;
}

/* apply to global */

