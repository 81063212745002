.auth__row {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  background-color: #f0f2f5;
  color: #1c1e21;
  background: linear-gradient(-45deg, #ea484d, #d6363b, #1b0553, #047a5f);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.auth__row main {
  height: 90vh;
  width: 100vw;
  position: relative;
  margin: 0 auto;
}

.auth__row footer {
  height: 10vh;
}

.auth__row__row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 600px) {
  .auth__row__row {
    display: grid !important;
    width: 50% !important;
  }
  .auth__colm__logo {
    margin-bottom: 2rem !important;
  }
}

.auth__colm__logo {
  flex: 0 0 50%;
  text-align: center;
  color: #9dbd42;
  font-size: 2.5rem;
}

.colm__form {
  flex: 0 0 40%;
  text-align: center;
}

.auth__colm__logo img {
  max-width: 204px;
}
.auth__colm__logo h1 {
  color: #9dbd42;
  font-size: 4rem !important;
}
.auth__colm__logo h2 {
  font: 26px;
  font-weight: 400;
  padding: 0 30px;
  line-height: 32px;
}

.colm__form :global(.form-container) {
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding: 20px;
  max-width: 400px;
}

.colm__form :global(.form-container input),
.colm__form :global(.form-container .btn-login) {
  width: 100%;
  margin: 5px 0;
  height: 45px;
  vertical-align: middle;
  font-size: 16px;
}

.colm__form :global(.form-container input) {
  border: 1px solid #dddfe2;
  color: #1d2129;
  padding: 0 8px;
  outline: none;
}

.colm__form :global(.form-container input:focus) {
  border-color: #ea484d;
  box-shadow: 0 0 0 2px #e7f3ff;
}

.colm__form :global(.form-container .btn-login) {
  background-color: #ea484d;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  padding: 0 16px;
  color: #ffffff;
  font-weight: 700;
}
.colm__form :global(.form-container .btn-login:hover) {
  background-color: #d6363b;
}

.colm__form :global(.form-container a) {
  display: block;
  color: #ea484d;
  font-size: 14px;
  text-decoration: none;
  padding: 10px 0 20px;
  border-bottom: 1px;
}

.colm__form :global(.form-container .btn-new) {
  background-color: #42b72a;
  border: none;
  border-radius: 6px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 16px;
  color: #ffffff;
  font-weight: 700;
  margin-top: 20px;
}

.colm__form p {
  font-size: 14px;
}

.colm__form p a {
  text-decoration: none;
  color: #1c1e21;
  font-weight: 600;
}

.colm__form p a:hover {
  text-decoration: underline;
}

/******************************/
.error {
  color: #d6363b;
  margin-bottom: 10px;
}
