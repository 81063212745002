footer html,
body {
  height: 100%;
  background: #f6f7fb;
}


footer #page-content {
  flex: 1 0 auto;
}

/* Other Classes for Page Styling */

footer body {
  opacity: 0; 
  background-color: rgba(0, 0, 0, 0.6);
}
