.user__photo {
    background-color: lightgrey;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    margin: 0 auto;
}

.user__card h5 {
    font-size: 16px;
}

.rate__text {
    font-size: 14px;
    color: lightgreen;
}